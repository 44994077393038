@import 'src/vars';

.pagination {
  display: flex;
  justify-content: space-between;
  padding-left: 1.11vw;
  padding-right: 1.11vw;

  @media #{$responsive-portrait} {
    padding: 0;
  }

  &__preview {
    object-fit: cover;
    width: 27.8vw;
    height: 9.45vw;

    @media #{$responsive-portrait} {
      display: none;
    }
  }

  &__navigation {
    margin-top: 1.46vw;

    font-family: acumin-pro-extra-condensed, sans-serif;
    text-transform: uppercase;
    color: #152CDC;

    cursor: pointer;
  }

  &__project-name {
    position: relative;
    display: block;
    font-size: 1.67vw;
    font-weight: 600;
    line-height: 1.8vw;

    &::after {
      content: '';
      position: absolute;
      top: 60%;
      background-image: url('./icons/arrow.svg');
      background-size: 2.7vw 2.7vw;
      width: 2.7vw;
      height: 2.7vw;
    }

    @media #{$responsive-portrait} {
      font-size: 4.62vw;
      line-height: 6.67vw;

      &::after {
        top: 50%;
        background-size: 10vw 10vw;
        width: 10vw;
        height: 10vw;
      }
    }
  }

  &__sub-name {
    display: block;
    margin-top: .7vw;
    font-size: 1.25vw;
    font-weight: 400;
    line-height: 1.8vw;

    @media #{$responsive-portrait} {
      margin-top: 3.1vw;
      font-size: 3.1vw;
      line-height: 5vw;
    }
  }
}

.pagination .pagination__prev .pagination__navigation {
  margin-left: 2.15vw;
  text-align: start;

  @media #{$responsive-portrait} {
    margin-left: 0;
  }
}
.pagination .pagination__next .pagination__navigation {
  margin-right: 2.15vw;
  text-align: end;

  @media #{$responsive-portrait} {
    margin-right: 0;
  }
}

.pagination .pagination__prev .pagination__project-name {
  &::after {
    left: -.5vw;
    transform: rotate(180deg);
  }
}
.pagination .pagination__next .pagination__project-name {
  &::after {
    right: -.5vw;
  }
}