@import "../../vars";

#contact-section {
  background-image: linear-gradient($c-yellow, $c-lightPink);
  @media #{$responsive-portrait} {
    overflow: hidden;
    height: 100vh;
  }

  #contact-main-container {
    width: 72vw;
    display: grid;
    grid-template-columns: 7.5% auto 12% auto;
    column-gap: 2vw;
    row-gap: 2vw;
    align-items: center;
    @media #{$responsive-portrait} {
      grid-template-columns: 100%;
      row-gap: 2vw;
      width: 87vw;
      position: absolute;
      transform: translate(-50%, 0%);
      top: 12.7vh;
    }

    .contact-main-title-lockup {
      grid-column: 1 / span 4;
      margin-bottom: 2vw;

      h1 {
        font-size: 8vw;
        padding: 0;
        margin: 0;
        line-height: 100%;
        position: relative;
        color: $c-purple;
        display: inline-block;

        &::before {
          content: "";
          background-color: $c-lightGreen;
          height: 32%;
          width: 105%;
          position: absolute;
          z-index: -1;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -25%);
        }
      }

      h2 {
        font-size: 5.2vw;
        position: relative;
        padding: 0;
        margin: 1vw 0 0 0;
        line-height: 60%;
        font-weight: 100;
      }
      @media #{$responsive-portrait} {
        grid-column: 1 / span 1;
        margin-bottom: 2vw;

        h1 {
          font-size: 10.4vw;
        }

        h2 {
          font-size: 7vw;
        }
      }
    }

    #caveat-contact-email-input {
      grid-column: 3 / span 2;
      @media #{$responsive-portrait} {
        grid-column: 1 / span 1;
      }
    }

    .caveat-contact-form-label {
      label {
        padding: 0;
        margin: 0;
        display: inline;
      }

      h3 {
        margin: 0;
        padding: 0;
        font-size: 3vw;
        display: inline-flex;
        line-height: 80%;
        @media #{$responsive-portrait} {
          font-size: 4vh;
          padding-top: 2vh;
        }
      }
    }

    input,
    select {
      display: inline-flex;
      margin: 0;
      font-size: 1.9vw;
      padding: 0vw 0.8vw 0.2vw 0.8vw;
      border: none;
      font-family: acumin-pro-extra-condensed, sans-serif;
      font-weight: 800;
      color: $c-purple;
      line-height: 100%;
      text-transform: uppercase;
      width: 100%;
      box-sizing: border-box;
      outline: none;
      @include striped-background($c-lightPurpleAlpha, 0.35vw, 2.3);

      user-select: auto;

      &::placeholder {
        color: $c-purple;
      }

      &:focus {
        @include striped-background($c-lightGreen, 0.35vw, 2.3);
        outline: none;
        color: $c-blue;
      }

      @media #{$responsive-portrait} {
        font-size: 3.5vh;
        padding: 0.5vh 1.4vh 0.5vh 1.4vh;
        @include striped-background($c-pink, 0.65vh, 2.3);

        &:focus {
          @include striped-background($c-lightGreen, 0.65vh, 2.3);
        }
      }
    }

    select {
      font-size: 1.9vw;

      option {
        font-weight: normal;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 1.2vw;
      }
      @media #{$responsive-portrait} {
        font-size: 3.5vh;
        margin-bottom: 2vw;
      }
    }

    .caveat-contact-info-container {
      position: fixed;
      grid-column: span 4;
      bottom: 8vh;
      left: 50%;
      transform: translateX(-50%);
      color: $c-purple;
      text-align: center;

      ul {
        list-style-type: none;
        list-style: none;
        padding: 0;
        margin: 0;
        color: $c-purple;

        li {
          display: inline-block;
          padding-right: 2.3vw;
          font-size: 2vw;
          color: $c-purple;

          a:visited,
          a {
            display: flex;
            svg {
              width: 2vw;
              fill: $c-purple;
              &:hover {
                fill: $c-pink;
              }
              @media #{$responsive-portrait} {
                height: 5vw;
                width: 5vw;
              }
            }
          }
          @media #{$responsive-portrait} {
            font-size: 3.5vh;
            padding-right: 2vh;
          }
        }
      }
      @media #{$responsive-portrait} {
        grid-column: 1 / span 1;
        position: relative;
        text-align: left;
        top: -5.8vh;
      }
    }
    .caveat-contact-submit {
      grid-column: 4 / span 1;
      text-align: right;
      padding-top: 1vw;
      @media #{$responsive-portrait} {
        grid-column: 1 / span 1;
        padding-top: 1.5vh;
      }

      button {
        padding: 0.4vw 4vw 0.6vw 4vw;
        background-color: $c-purple;
        font-family: acumin-pro-extra-condensed, sans-serif;
        font-size: 2vw;
        color: $c-lightPurple;
        line-height: 100%;
        text-transform: uppercase;
        cursor: pointer;

        &:hover {
          background-color: $c-blue;
          color: $c-pink;
        }
        @media #{$responsive-portrait} {
          font-size: 3vh;
          padding: 0.7vh 3.2vh 1.3vh 3.2vh;
        }
      }
    }
  }
}

.caveat-small-contact {
  padding: 4vw 15vw;
  @media #{$responsive-portrait} {
    padding: 3vh 3.5vh;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    display: flex;
  }

  h1 {
    font-size: 5vw;
    padding: 0;
    margin: 0;
    line-height: 100%;
    position: relative;
    color: $c-purple;
    z-index: 5;
    @media #{$responsive-portrait} {
      font-size: 7vw;
    }

    &::before {
      content: "";
      background-color: $c-lightPurple;
      height: 32%;
      width: 105%;
      position: absolute;
      z-index: -1;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -25%);
    }
  }

  h2 {
    font-size: 5vw;
    position: relative;
    padding: 0;
    margin: 0;
    line-height: 100%;
    text-align: left;
    font-weight: 100;
    @media #{$responsive-portrait} {
      font-size: 5vw;
    }
  }

  .caveat-logo-svg {
    width: 9vw;
    margin-left: -0.4vw;
    margin-bottom: 2vw;
    fill: $c-purple;
    @media #{$responsive-portrait} {
      width: 15vh;
      display: none;
    }
  }

  .caveat-contact-info {
    display: inline-block;
    font-size: 1.2vw;
    pointer-events: visible;
    padding: 1vw 2vw 0 0;
    margin: 2vw 0;
    @media #{$responsive-portrait} {
      font-size: 2.5vh;
      margin-top: 2vh;
      padding-top: 2vh;
    }

    li {
      list-style-type: none;
      padding-bottom: 0.3vw;
      font-weight: 600;
      font-size: calc(1.5vw + .5vh);
      text-transform: uppercase;
      margin: 0 0 2vh;
      @media #{$responsive-portrait} {
        padding-bottom: 1vh;
        font-size: calc(2.5vw + .5vh);
        margin: 0 0 0.5vh;
      }

      a {
        border-bottom: .1vh solid $c-lightPurple;
        color: $c-purple;
        position: relative;
        z-index: 5;

        &::before {
          content: "";
          clip-path: inset(0% 0% 0% 100%);
          background-color: $c-pink;
          height: 30%;
          width: 100%;
          position: absolute;
          z-index: -1;
          top: 46%;
          transform: translate(0%, -25%);
          animation: menu-slide-out 500ms cubic-bezier(1, 0, 0, 1);
        }

        &:hover {
          &::before {
            clip-path: inset(0% 0% 0% 0%);
            animation: menu-slide-in 500ms cubic-bezier(1, 0, 0, 1);
          }
        }
      }
      
      a .--caveat-icon {
        position: relative;
        font-size: 1vw;
        margin-right: 0.7vw;
        color: $c-purple;
        top: 0.1vw;
        @media #{$responsive-portrait} {
          font-size: 2.5vh;
          margin-right: 1.2vh;
          top: 0.4vh;
        }
      }
    }
  }

  .caveat-contact-backup-container {
    pointer-events: visible;
    position: absolute;
    right: 12vw;
    bottom: 17vh;
    cursor: pointer;
    padding: 1vh;
    z-index: 200;

    h2 {
      font-size: 4vmin;
      margin: 0;
      padding: 0;
      color: $c-blue;
    }

    .caveat-arrow {
      position: absolute;
      width: 10vmin;
      top: 0;
      left: 2px;
      transform: translateY(-100%);
    }
  }
}
