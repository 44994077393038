@import 'src/vars';

.simple-contact {
  color: #152CDC;

  &__headline {
    margin-bottom: 2.9vh;
  }

  h1 {
    font-size: 5.2vw;
    line-height: 5.2vw;
    font-style: normal;
    font-weight: bold;
    text-transform: uppercase;

    margin-block-start: .15em;
    margin-block-end: .15em;

    @media #{$responsive-portrait} {
      font-size: 8.2vw;
      line-height: 8.2vw;
    }
  }

  h2 {
    font-size: 3.3vw;
    line-height: 3.3vw;
    font-style: normal;
    font-weight: normal;


    margin-block-start: 0;
    margin-block-end: 0;

    @media #{$responsive-portrait} {
      font-size: 3.6vw;
      line-height: 3.6vw;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: center;

    > span {
      margin-bottom: 26px;
    }

    > span:last-child {
      margin-bottom: 0;
    }
  }

  &__field {
    position: relative;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;

    a {
      position: relative;
      z-index: 1;
    }

    a::before {
      content: '';
      background-color: #F9B9D1;
      height: 110%;
      width: calc(100% + 0.5em);
      position: absolute;
      z-index: -1;
      left: 50%;
      top: 25%;
      transform: translate(-50%, -25%);
    }

    &_contact {
      a::before {
        height: 35%;
        top: 70%;
      }
    }

    &_hidden {
      display: none;
    }
  }

}