@import url("https://use.typekit.net/mih5zhf.css");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

@import "vars";

html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  overflow-y: hidden;
}

body {
  background-color: white;
  font-family: open-sans-condensed, sans-serif;
  font-weight: 300;
  font-style: normal;
  color: $c-purple;
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
  user-zoom: none;
}

#root {
  height: 100%;
}

h1,
h2,
h3 {
  font-family: acumin-pro-extra-condensed, sans-serif;
  text-transform: uppercase;
  line-height: 100%;
}
.invisible {
  display: none;
}

// a[href^="tel:"]:before {
//   content: "\260e";
//   margin-right: 0.5em;
//   font-family: Arial, Helvetica, sans-serif;
//   font-size: 200%;
// }
a,
a:visited {
  text-decoration: none;
  color: $c-blue;
}
a[href^="tel:"] {
  text-decoration: none;
}

.caveat-section {
  position: fixed;
  overflow: hidden;
  height: 100%;
  width: 100vw;
  z-index: 0;
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;

  // @media #{$responsive-portrait} {
  //   overflow: hidden;
  //   height:100vh;
  //   width:100vw;
  // }
}

.caveat-arrow {
  fill: $c-blue;
}

.caveat-small-logo-svg {
  fill: $c-lightPurple;
  // border:2px solid #0f0;
  width: 4vw;
  animation: spinner 0.8s linear infinite;
  @media #{$responsive-portrait} {
    width: 10vh;
  }
}

$inactive: $c-lightPurple;
$active: $c-blue;
$speed: 0.8s;
$size: 3vw;
$unit: $size / 16;

%spinner {
  display: block;
  float: left;
  width: $size;
  height: $size;
  border-radius: 50%;
  border: $unit solid $inactive;
  animation: spinner $speed linear infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotateY(360deg);
  }
}

// body {
//   border: 10px solid green;
// }
//caveat-talent-filters
.caveat-talent-filters-popover-container {
  background: white;
  
  .caveat-talent-filters-popover {
    padding: 1vw;

    .caveat-filter-group {
      padding-bottom: 0.5vw;
      @media #{$responsive-portrait} {
        // border:1px solid #00f;
        //padding: 0vmin 0;
        padding: 0;
        line-height: 2.5vmin;
      }
    }
  }

  [type="checkbox"]:not(:checked),
  [type="checkbox"]:checked {
    position: absolute;
    left: -9999px;
  }
  [type="checkbox"]:not(:checked) + label,
  [type="checkbox"]:checked + label {
    position: relative;
    padding-left: 2vw;
    cursor: pointer;
    font-size: 1.3vw;
    color: $c-lightPurple;
    font-weight: 800;
    white-space: nowrap;
    @media #{$responsive-portrait} {
      font-size: 1.9vmin;
      padding-left: 3vmin;
      //text-transform: uppercase;
    }
  }
  [type="checkbox"]:checked + label {
    color: $c-purple;
  }

  [type="checkbox"]:not(:checked) + label:before,
  [type="checkbox"]:checked + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 1.3vw;
    height: 1.3vw;
    background: rgba($color: $c-lightPurple, $alpha: 0.2);
    line-height: 100%;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: all 0.1s;
    @media #{$responsive-portrait} {
      width: 2.2vw;
      height: 2.2vw;
    }
  }
  [type="checkbox"]:checked + label:before {
    background: $c-green;
    transition: all 0.1s;
  }

  [type="checkbox"]:not(:checked) + label:after,
  [type="checkbox"]:checked + label:after {
    content: "\2715\0020";
    position: absolute;
    left: 0.62vw;
    top: 47.5%;
    transform: translate(-50%, -50%);
    font-size: 1vw;
    line-height: 10%;
    color: white;
    transition: all 0.1s;
    opacity: 0;
    font-family: "Arial Unicode MS", Arial;
    text-shadow: 0.08vw 0.08vw #00000022;
    @media #{$responsive-portrait} {
      left: 1vmin;

      font-size: 1.8vmin;
      font-weight: 800;
    }
  }
  [type="checkbox"]:not(:checked) + label:after {
    opacity: 1;
    transform: translate(-50%, -50%) scale(0) rotate(-180deg);
    transition: all 0.1s;
  }
  [type="checkbox"]:checked + label:after {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1) rotate(0deg);
    transition: all 0.3s ease-out;
  }
}
