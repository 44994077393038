$c-blue: #152cdc;
$c-lightBlue: #8a95ee;
$c-green: #00df8a;
$c-lightGreen: #80efc4;
$c-purple: #694cff;
$c-lightPurple: #b4a5ff;
$c-lightPurpleAlpha: #b4a5ffaa;
$c-lightPurpleAlphaStripe: #b4a5ff52;
$c-yellow: #f8ff02;
$c-pink: #f9b9d1;
$c-lightPink: #fcdce8;
$c-clear: rgba(0, 0, 0, 0);
$title-size: 10vw;

$responsive-portrait: "(orientation: portrait)";
$responsive-landscape: "(orientation: landscape)";
$responsive-portrait-mobile: "only screen and (min-width: 575.98px) and (orientation: portrait)";


@mixin striped-background($color, $size, $spacing: 4) {
  background: repeating-linear-gradient(
    -45deg,
    $color,
    $color $size,
    rgba(0, 0, 0, 0) $size,
    rgba(0, 0, 0, 0) $size * $spacing
  );
}

@mixin arrow($color, $size: 2vw) {
  content: "";
  clip-path: polygon(
    49% 0%,
    49% 90%,
    45% 90%,
    50% 100%,
    55% 90%,
    51% 90%,
    51% 0%
  );
  background-color: $color;
  width: $size;
  height: $size;
  display: inline-block;
  position: absolute;
}

