@import "../../vars";

$content-top: 9vw;

html {
  --clipPathPercent: 50%;
}

#landing-section {
  background-image: linear-gradient($c-pink, white);
  * {
    user-select: none;
    user-zoom: none;
  }

  #landing-back-up-hidden {
    width: 10vw;
    height: 10vw;
    position: fixed;
    // background-color: green;
    top: 0;
    left: 0;
    z-index: 3000;
    @media #{$responsive-portrait} {
      width: 20vw;
      height: 11vw;
    }
  }
  .caveat-title-lockup {
    color: $c-blue;
    position: relative;

    h1 {
      margin: 0;
      padding: 0;
      line-height: 7vw;
      font-size: $title-size;
      position: absolute;
      letter-spacing: -0.1rem;
      font-size: 7vw;
      @media #{$responsive-portrait} {
        font-size: 9vw;
        letter-spacing: 0rem;
        color: $c-purple;
        // left: 8vh;
        // top: 3vh;
        position: relative;
        display: inline-block;
        width: 100%;
        line-height: 1.2;
      }
      &:nth-of-type(2) {
        font-size: 5vw;
        line-height: 60%;
        z-index: 10;
        top: 7.5vw;
        @media #{$responsive-portrait} {
          font-size: 9vw;
          top: unset;
          width: unset;
          // margin-top:3vh;
          line-height: 60%;
          margin-bottom: 10px;
        }
        &::before {
          content: "";
          background-color: $c-lightPurple;
          height: 60%;
          width: 105%;
          position: absolute;
          z-index: -1;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -25%);
        }
      }
    }
  }

  #landing-title {
    position: absolute;
    left: 2vw;
    width: 53vw;
    top: calc(2vw + 15vh);
    @media #{$responsive-portrait} {
      position: fixed;
      // bottom:10vh;
      // bottom: 0vh;
      top: 0;
      @include striped-background($c-lightPurpleAlphaStripe, 0.3vh, 3);
      background-color: #FFFFFFE8;
      // height: 30vh;
      width: 100vw;
      left: 0vh;
      padding: 10vh 2vh 2vh 3vh;
      height: calc(40vw + 8vh);

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      //padding-bottom: 12vh;
      // transform: translateY(-50%);
      // border-radius: 4vw;
    }
  }

  .circs {
    position: fixed;
    width: 100vw;
    height: 0vh;
    bottom: 0px;
    // clip-path: inset(100% 0% 0% 0%);
    @include striped-background($c-lightBlue, 0.5vw, 2.8);
    @media #{$responsive-portrait} {
      @include striped-background($c-lightBlue, 1vw, 2.8);
    }
    z-index: 100;
  }

  #landing-img-vid {
    position: fixed;
    left: 43vw;
    top: calc(2vw + 10vh);
    width: calc(57vw - 3vh);
    z-index: -1;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    clip-path: circle(100%);
    background-color: $c-lightBlue;
    box-shadow: 0.5vw 0.5vw 0 rgba(0, 0, 0, 0.1);

    video {
      // width: 130vw;
      height: calc(90vh + 3vw);
      border-top: 0vw solid $c-green;
    }
    img {
      width: 100%;
    }

    @media #{$responsive-portrait} {
      background-color: $c-pink;
      left: 0;
      top: 0;
      width: 100vw;
      height: 100vh;
      //margin-top: calc(40vw + 3vh);
      video {
        width: unset;
        height: 100vh;
      }
    }
  }

  #landing-menu {
    position: absolute;
    top: calc(11vw + 24vh);
    left: 1vw;
    z-index: 100;
    @media #{$responsive-portrait} {
      left: 2vw;
      top: calc(40vw + 8vh - 30px);
    }

    ul {
      padding: 0;
      padding-left: 1vw;
      @media #{$responsive-portrait} {
        padding: 0;
        padding-left: 2vw;
        min-width: 60vw;
        text-align: center;
      }

      li {
        list-style: none;
        list-style-type: none;
        font-weight: 600;
        padding: 0;
        margin: 0;
        font-size: calc(1.8vw + 0.9vh);
        text-transform: uppercase;
        color: $c-purple;
        margin-bottom: 2vh;
        position: relative;
        @media #{$responsive-portrait} {
          background-color: white;
          font-size: calc(1.2vw + 1.5vh);
          margin-bottom: 1vh;
          padding: 0.5vh 1.5vh;
        }

        a {
          text-decoration: none;
          color: $c-purple;
          position: relative;
          border-bottom: 0.1vh solid $c-lightPurple;
          @media #{$responsive-portrait} {
            border-bottom: none;
          }

          &::before {
            content: "";
            // @include striped-background($c-pink, 0.1vw, 2);
            clip-path: inset(0% 0% 0% 100%);
            background-color: $c-lightGreen;
            height: 30%;
            width: 105%;
            position: absolute;
            z-index: -1;
            left: -1%;
            top: 50%;
            transform: translate(0%, -25%);
            animation: menu-slide-out 500ms cubic-bezier(1, 0, 0, 1);
          }

          &:hover {
            &::before {
              clip-path: inset(0% 0% 0% 0%);
              animation: menu-slide-in 500ms cubic-bezier(1, 0, 0, 1);
            }
          }
        }
      }
    }
  }

  @keyframes menu-slide-in {
    from {
      clip-path: inset(0% 100% 0% 0%);
    }
    to {
      clip-path: inset(0% 0% 0% 0%);
    }
  }

  @keyframes menu-slide-out {
    from {
      clip-path: inset(0% 0% 0% 0%);
    }
    to {
      clip-path: inset(0% 0% 0% 100%);
    }
  }

  #landing-more-indicator {
    position: fixed;
    bottom: 11vh;
    left: calc(10vh + 12vw);
    transform: translateX(-50%);
    cursor: pointer;
    z-index: 200;

    h3 {
      padding: 0px;
      margin: 0px;
      font-size: calc(1.5vw + 0.5vh);
      line-height: 60%;
      color: $c-purple;
      &:hover {
        color: $c-pink;
      }
      @media #{$responsive-portrait} {
        font-size: 2.5vh;
        line-height: 100%;
        color: white;
        display: inline-block;
      }
    }

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 2.8vh;
      @include striped-background($c-lightPurpleAlpha, 0.2vw, 2.6);
      z-index: 1;
      opacity: 1;
    }

    @media #{$responsive-portrait} {
      left: unset;
      width: unset;
      right: unset;
      top: unset;
      bottom: 1vh;
      transform: translateX(0%);
      text-align: center;
      background-color: $c-purple;
      padding: 1vh 5vh;
      &::after {
        display: none;
      }
    }
  }
  #landing-more-indicator-arrow {
    position: fixed;
    bottom: 2.3vh;
    left: calc(10vh + 12vw);

    transform: rotate(180deg) translateX(50%);
    width: 9vh;
    @media #{$responsive-portrait} {
      width: 14vh;
      left: unset;
      right: -2.5vh;
      // right:12.5vh;
      fill: $c-blue;
      // top:68.5vh;
      top: unset;
      bottom: 3vh;
      transform: rotate(180deg);
    }
  }

  #landing-showmore-title {
    z-index: 120;
    background: white;
    color: $c-lightBlue;
    padding: 1vw 2vw;
    position: fixed;
    top: 11vh;
    right: 7vw;
    clip-path: inset(10% 0 0 100%);
    border-right: 10vw solid $c-lightBlue;
    white-space: nowrap;
    @media #{$responsive-portrait} {
      top: unset;
      right: unset;
      bottom: 74%;
      width: 87vw;
    }
    h1 {
      font-size: 8vw;
      line-height: 100%;
      margin: 0;
      padding: 0;
      @media #{$responsive-portrait} {
        font-size: 13vw;
      }
    }
  }

  #landing-backup-container {
    position: fixed;
    top: -11vmin;
    left: 20vmin;
    cursor: pointer;
    background-color: white;
    padding: 2vh;
    z-index: 200;
    h2 {
      font-size: 4vmin;
      margin: 0;
      padding: 0;
    }
    .caveat-arrow {
      position: absolute;
      width: 10vmin;
      top: 0;
      transform: translateY(-100%);
    }
  }
  #landing-showmore-copy {
    z-index: 120;
    background-color: $c-lightPurple;
    color: white;
    padding: 3vw;
    position: fixed;
    width: 70vw;
    left: 3vw;
    clip-path: inset(0% 100% 0% 0%);
    bottom: 12vh;
    h2 {
      font-size: 3vw;
      padding-bottom: 0.5vw;
      margin: 0;
      width: calc(80vw - 68vh);
    }
    p {
      padding-bottom: 0.5vw;
      margin: 0;
      width: calc(80vw - 68vh);
      font-size: 1.8vw;
    }
    a {
      border-bottom: 0.1vh solid rgba(20, 44, 219, 0.3);
      font-size: 1.3vw;
    }
    @media #{$responsive-portrait} {
      width: 87vw;
      left: 50%;
      transform: translateX(-50%);
      bottom: 3vh;
      h2 {
        font-size: 8vw;
        margin-bottom: 3vw;
        width: 100%;
      }
      p {
        width: 100%;
        font-size: 4vw;
      }
      a {
        font-size: 3.5vw;
      }
    }
  }
  #landing-showmore-image {
    z-index: 120;
    position: fixed;
    width: calc(20vw + 48vh);
    right: 2.5vmin;
    left: unset;
    bottom: 16vh;
    clip-path: inset(100% 0% 0% 0%);
    &.--video {
      width: calc(20vw + 48vh);
      right: 2.5vmin;
      left: unset;
      bottom: 16vh;
      box-shadow: 0.5vw 0.5vw 0 rgba(0, 0, 0, 0.1);
    }
    @media #{$responsive-portrait} {
      transform: none;
      bottom: 41% !important; 
      z-index: 100;
      left: 6.5vw !important;
      width: 87vw !important;
      &.--video {
        bottom: 38%;
      }
    }
    img {
      width: 42vw;
      box-shadow: 0.5vw 0.5vw 0 rgba(0, 0, 0, 0.1);
      border-left: 30vw solid $c-yellow;
      box-sizing: border-box;
      @media #{$responsive-portrait} {
        width: 87vw;
      }
    }
  }
}
