@import "../../vars";

#section-mask {
  @media #{$responsive-portrait} {
    content: "";
    background-color: $c-lightPink;
    height: 9.5vh;
    width: 100vw;
    position: absolute;
    z-index: 200;
    top: 0;
  }
}

#talent-section {
  position: absolute;
  background-color: $c-lightPink;
  background-image: linear-gradient($c-lightPink, white);
  display: grid;
  grid-template-columns: 13% 87%;
  grid-template-rows: auto auto;
  //row-gap: 6vmin;
  align-items: flex-start;
  align-content: flex-start;
  overflow-y: scroll !important;
  position: fixed;
  left: 50%;
  padding: 8vmin 10vw;
  transform: translate(-50%, 0);
  padding: 12vh 4vw;
  grid-template-columns: 100%;

  * {
    user-select: none;
    z-index: 0;
    position: relative;
  }
  h1,
  h2,
  h3,
  h4 {
    margin: 0;
    padding: 0;
    line-height: 100%;
  }

  // @media #{$responsive-portrait} {
  //   padding: 12vh 4vw;
  //   grid-template-columns: 100%;
  // }

  .caveat-talent-filters-title {
    display: flex;
    justify-content: space-between;

    .caveat-talent-filters-title-item {
      font-size: 2vw;
      @media #{$responsive-portrait} {
        font-size: 3vw;
      }
    }

    .clear-filters {
      cursor: pointer;
    }
  }

  .caveat-talent-title-lockup {
    padding-bottom: 4vw;
    grid-column: 1 / span 1;
    // @media #{$responsive-portrait} {
    //   grid-column: 1 / span 1;
    // }
    h1 {
      position: relative;
      display: inline-block;
      font-size: 7.5vw;
      line-height: 80%;

      @media #{$responsive-portrait} {
        font-size: 10vmin;
      }
      &::before {
        content: "";
        background-color: $c-pink;
        height: 45%;
        width: 103%;
        position: absolute;
        z-index: -1;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -25%);
      }
    }
    h2 {
      margin-top: 0.5vw;
      font-size: 3.5vw;
      line-height: 80%;
      @media #{$responsive-portrait} {
        margin-top: 2vw;
        font-size: 5vmin;
      }
    }
  }
  .caveat-talent-filters {
    padding-bottom: 2vh;
    //@media #{$responsive-portrait} {
    display: grid;

    grid-template-columns: calc(25% - 3vw) calc(25% - 3vw) calc(25% - 3vw) calc(25% - 3vw);
    column-gap: 4vw;

    .caveat-filter-group-container {
      display: grid;
      grid-template-columns: auto;
      align-content: flex-start;
      column-gap: 1vmin;
      //background-color: white;
      //padding: 1vmin;
      
      .caveat-filter-group-popover-opened,
      .caveat-filter-group-popover-closed {
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        right: 0;
        top: 35%;
        height: 1vw;
        width: 1vw;
        @media #{$responsive-portrait} {
          top: 40%;
        }
      }

      .caveat-filter-group-popover-opened {
        background-image: url('../../assets/popup-opened.svg');
      }

      .caveat-filter-group-popover-closed {
        background-image: url('../../assets/popup-closed.svg');
      }
    }
    //}

    h4 {
      margin-top: 1vmin;
      margin-bottom: 0.5vmin;
      border-bottom: 0.3vmin solid $c-pink;
      font-size: 1.8vw;
      padding-bottom: 1vmin;
      @media #{$responsive-portrait} {
        font-size: 2.7vw;
      }

      &:first-of-type {
        // margin-top: 0;
      }
      // @media #{$responsive-portrait} {
      //   grid-column: span 1;
      //   margin-top: 0vmin;
      //   margin-bottom: 0vmin;
      //   padding-bottom: 0.7vmin;
      //   border-bottom: 0.5vmin solid $c-purple;
      //   text-transform: uppercase;
      // }

      &.active {
        color: $c-pink;
      }
    }
  }
  /************************************************
  Talent Outpus
  ************************************************/
  .caveat-talent-output {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto;
    align-items: stretch;
    align-content: flex-start;
    column-gap: 1.7vw;
    row-gap: 2vmin;
    margin-top: 1vmin;
    //padding-left: 2.5vmin;
    padding-bottom: 7vmin;

    @media #{$responsive-portrait} {
      padding-left: 0;
      grid-template-columns: auto;
      margin-top: 0;
      column-gap: 0;
    }
    .caveat-creatorProject-container {
      cursor: pointer;
      h2 {
        font-size: 3vmin;
      }
      .caveat-creatorProject-preview-container {
        position: relative;
        max-width: 93vmin;
        // background-color: $c-lightGreen;
        img {
          width: 100%;
          margin: 0;
          padding: 0;
          // border: 0.8vmin solid $c-green;
          transition: all 0.3s ease-in-out;
          box-shadow: 0.6vmin 0.6vmin 0px #00000011;
        }
      }
      .caveat-creatorProject-title-lockup {
        font-size: 1.3vw;
        line-height: 105%;
        margin-top: 0.6vw;
        strong {
          font-size: 1.4vw;
          text-transform: uppercase;
          display: block;
        }
        @media #{$responsive-portrait} {
          font-size: 1.9vh;
          margin-top: 1vh;
          margin-bottom: 3vh;
        }
      }

      transition: all 1s ease-in-out;

      &:hover {
        transition: all 1s ease-in-out;
        img {
          box-shadow: -1vmin 1vmin 0px #00000017;
          transition: all 0.3s ease-in-out;
          // border: 0.8vmin solid $c-pink;
        }
      }
    }
  }
  /************************************************
  Talent Light Box
  ************************************************/

  /***********************************************/
  /***********************************************/
  /***********************************************/
}

.caveat-talent-lightBox {
  position: fixed;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  // transform: rotate(20deg);
  clip-path: inset(0% 0% 100% 0%);

  @include striped-background(
    rgba($color: $c-lightPurple, $alpha: 0.2),
    0.2vw,
    7
  );

  background-color: $c-purple;
  .caveat-talent-lightbox-navigation {
    position: absolute;
    z-index: 20;

    width: 100%;
    height: 100%;
    .caveat-talent-lightbox-button {
      position: absolute;
      width: 50%;
      height: 100%;
      background-color: rgba($color: #ff0000, $alpha: 0);
      cursor: pointer;

      &:hover {
        .caveat-arrow {
          fill: $c-purple;
        }
      }
      .caveat-arrow {
        width: 10vw;
        position: absolute;
        top: 50%;
        transform: rotate(-90deg) translateX(50%);
        fill: $c-lightPurple;
        transition: all 1s;
        @media #{$responsive-portrait} {
          width: 20vh;
          bottom: -5%;
          top: unset;
          fill: $c-purple;
          left: 0;
        }
      }

      &.--next {
        right: 0;

        .caveat-arrow {
          left: unset;
          right: 0;
          transform: rotate(90deg) translateX(-50%);
        }
      }
    }
  }
  .caveat-item-container {
    // z-index: 100;
    position: relative;
    opacity: 0;
    top: -10vh;
    text-align: center;
    .caveat-lightbox-closebutton {
      background: $c-purple;
      color: white;
      position: absolute;
      // right: 0;
      transform: translateX(-100%);
      font-size: 3vw;
      z-index: 100000;
      cursor: pointer;

      &:hover {
        background-color: $c-lightPurple;
      }
      @media #{$responsive-portrait} {
        // top: calc(30% - 10vw);
        font-size: 5vh;
      }
    }
    img {
      height: 60vh;
      // border: 1vw solid $c-purple;
      background-color: $c-purple;
      box-shadow: -1vmin 1vmin 0px #00000011;
      opacity: 0;
      @media #{$responsive-portrait} {
        box-shadow: -1vh 1vh 0px #00000033;
        width: 90vw;
        height: unset;
      }
    }
    .--video {
      width: 85vh;
      // border: 1vw solid $c-purple;
      background-color: $c-purple;
      box-shadow: -1vmin 1vmin 0px #00000011;
      z-index: 100;
      opacity: 0;
      @media #{$responsive-portrait} {
        box-shadow: -1vh 1vh 0px #00000033;
        width: 90vw;
      }
      .caveat-lightbox-closebutton {
        right: 0vw;
        top: 0;
        transform: unset;
        // transform: translateY(-50%);
      }
    }

    .caveat-talent-lightbox-title-lockup {
      clip-path: inset(0% 100% 0% 0%);
      // border: 1vw solid #0f0;
      // width: 94vh;
      overflow: hidden;
      text-align: left;
      h1 {
        font-size: 4.5vw;
        width: 100%;
        line-height: 100%;
        padding: 0;
        margin: 0;
        border-left: 50vw solid $c-lightPurple;
        background-color: rgba($color: $c-purple, $alpha: 1);
        text-align: left;
        @media #{$responsive-portrait} {
          font-size: 3vh;
          margin-top: 3vh;
        }
      }
      h3 {
        text-align: left;
        display: inline;
        font-size: 2vw;
        border-left: 50vw solid $c-purple;
        background-color: rgba($color: $c-purple, $alpha: 1);
        word-wrap: none;
        &::after {
          content: " - ";
        }
        @media #{$responsive-portrait} {
          font-size: 1.5vh;
        }
      }
      .--filters {
        display: inline;
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
          display: inline;
          &:after {
            content: ", ";
          }
          font-size: 1.7vw;
          @media #{$responsive-portrait} {
            font-size: 1.5vh;
          }
        }
      }
    }
  }
}
