@import 'src/vars';

.side-panel {

  &__arrow {
    content: '';
    position: absolute;
    bottom: 7vh;
    transform: translateY(50%);
    right: 2.5vw;
    background-image: url('./icons/arrow.svg');
    background-size: 3vw 2.9vh;
    width: 3vw;
    height: 2.9vh;

    z-index: 1500;

    @media #{$responsive-portrait} {
      bottom: calc(3.9vh + 6.3vw);
      right: 2vw;
      background-image: url('./icons/arrow_mobile.svg');
      background-size: 7vw 7vw;
      width: 7vw;
      height: 7vw;
    }

    &_expanded {
      transform: rotate(180deg) translateY(-50%);
    }
  }

  &__tag {
    cursor: pointer;
  }

  &__label {
    position: absolute;
    z-index: 1499;
    bottom: 3.9vh;
    right: 0;

    color: #152CDC;

    background-color: #FCFFA9;
    padding: 1.95vh .7vw 5.37vh .8vw;
    font-size: 1.7vw;
    font-weight: 600;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    text-transform: uppercase;
    font-family: acumin-pro-extra-condensed, sans-serif;

    @media #{$responsive-portrait} {
      font-size: 6.15vw;
      line-height: 6.15vw;
      padding: 2.8vw 1.5vw 12.6vw 3vw;
    }
  }

  &__content {
    position: absolute;
    z-index: 1499;
    top: 0;
    right: -34.5vw;
    width: 34.5vw;
    height: 100vh;
    padding: 8.3vh 3.42vw;

    background-color: #FCFFA9;
    transition: right .4s cubic-bezier(.820, .085, .395, .895);

    @media #{$responsive-portrait} {
      right: -93.8vw;
      width: 93.8vw;

      padding: 6.28vh 6.15vw;
    }

    &_expanded {
      right: 0;
    }
  }

  &__title {
    display: inline-block;
    font-size: 4.69vh;
    font-weight: 600;
    line-height: 5.08vh;
    text-transform: uppercase;

    color: #152CDC;

    @media #{$responsive-portrait} {
      font-size: 4.03vh;
      line-height: 4.27vh;
    }
  }

  &__description {
    display: inline-block;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 1.37vh;
    line-height: 1.76vh;
    margin-top: 2.44vh;
    margin-bottom: 2.05vh;

    color: #000;

    @media #{$responsive-portrait} {
      font-size: 1.42vh;
      line-height: 2.13vh;

      margin-top: 2.13vh;
      margin-bottom: 2.6vh;
    }
  }

  &__tags {
    font-family: acumin-pro-condensed, sans-serif;
    font-weight: 600;
    font-size: 1.76vh;
    line-height: 2.54vh;
    text-transform: uppercase;

    color: #B4A5FF;

    @media #{$responsive-portrait} {
      font-size: 2.13vh;
      line-height: 3.08vh;
    }
  }

}
