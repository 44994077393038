@import "../../vars";

.caveat-loadingscreen-container {
  position: fixed;
  background-color: $c-lightPurple;
  z-index: 2000;
  clip-path: circle(100%);
  animation: 10s circle ease-in-out;
}

@keyframes circle {
  0% {
    clip-path: circle(75%);
  }
  100% {
    clip-path: circle(0%);
  }
}
