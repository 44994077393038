@import 'src/vars';

.new-work {
  --pattern-animation: radial-gradient(#152CDC 15px, transparent 15px), radial-gradient(#152CDC 15px, transparent 15px);
  --opacity-animation: .25;

  background-image:  linear-gradient(#F9B9D1, #FFFFFF);
  overflow-y: scroll;
  height: 100%;
  font-family: acumin-pro-extra-condensed, sans-serif;
  letter-spacing: 0.025em;

  &::-webkit-scrollbar {
    display: none;
  }

  &__content {
    padding: 10.7vh 2.3vw;

    @media #{$responsive-portrait} {
      padding: 14.7vh 6vw 7.2vh;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    color: #152CDC;
  }

  &__title {
    font-size: 10vw;
    line-height: 9vw;
    font-style: normal;
    font-weight: bold;
    text-transform: uppercase;
    word-break: break-word;

    @media #{$responsive-portrait} {
      font-size: 18.2vw;
      line-height: 16.5vw;
    }
  }

  &__sub-title {
    margin-top: .2vw;
    display: inline-block;
    font-style: normal;
    font-weight: normal;
    font-size: 3.4vw;
    line-height: 4vw;
    text-transform: uppercase;

    @media #{$responsive-portrait} {
      font-size: 9vw;
      line-height: 10.8vw;
    }
  }

  &__items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.1vw;
    margin-top: 5vw;

    @media #{$responsive-portrait} {
      grid-template-columns: 1fr;
      grid-gap: 2vw;
      margin-top: 9vw;
    }
  }

  &__item {
    position: relative;
    height: 31.8vw;
    width: 100%;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      background-image: var(--pattern-animation);
      background-size: 20px 20px;
      background-position: 0 0, 10px 10px;
      background-repeat: repeat;
      opacity: var(--opacity-animation);
      height: 100%;
      width: 100%;
      z-index: 1;
      top: 0;
      left: 0;
    }

    @media #{$responsive-portrait} {
      height: 59.5vw;
    }
  }

  &__preview {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__banner {
    width: 47%;
    background-color: #152CDC;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    z-index: 2;

    padding: 1.2% 2% 2%;

    display: flex;
    flex-direction: column;
    align-items: center;

    color: #F9B9D1;

    @media #{$responsive-portrait} {
      width: 60%;
      padding: 2% 3.7% 3.7%;
    }
  }

  &__project-name {
    display: block;
    font-style: normal;
    font-weight: 600;
    font-size: 2vw;
    line-height: 2vw;

    text-align: center;
    text-transform: uppercase;

    @media #{$responsive-portrait} {
      font-size: 6.6vw;
      line-height: 6.6vw;
    }
  }

  &__client-name {
    display: block;
    margin-top: .2vw;
    font-style: normal;
    font-weight: 600;
    font-size: 1.11vw;
    line-height: 1.39vw;

    text-align: center;
    text-transform: uppercase;

    @media #{$responsive-portrait} {
      font-size: 3.59vw;
      line-height: 4.6vw;
    }
  }

  &__footer {
    margin-top: 16vh;

    @media #{$responsive-portrait} {
      margin-top: 15.9vw;
    }
  }
}