@import 'src/vars';

.new-case-study {
  --pattern-animation: radial-gradient(#152CDC 15px, transparent 15px), radial-gradient(#152CDC 15px, transparent 15px);
  --opacity-animation: 0;
  --top-animation: 0;
  --left-animation: 0;
  --top-animation-mobile: 0;
  --left-animation-mobile: 0;

  position: relative;
  height: 100%;
  font-family: acumin-pro-extra-condensed, sans-serif;
  letter-spacing: 0.025em;

  &__back {
    position: absolute;
    top: 5.9vw;
    left: 1.4vw;

    font-size: 1.25vw;
    font-weight: 600;
    line-height: 1.5vw;
    text-transform: uppercase;

    color: #152CDC;

    cursor: pointer;

    @media #{$responsive-portrait} {
      display: none;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: calc(100% - .2em);
      left: calc(-.9vw);
      background-image: url('./icons/arrow.svg');
      background-size: calc(100% + .95vw) .5em;
      width: calc(100% + .95vw);
      height: .5em;
    }

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      padding: .7vw;
      transform: translate(-25%, -25%);
    }
  }

  &__content {
    background-image: linear-gradient(#F9B9D1, #FFFFFF);
    overflow-y: scroll;
    height: 100%;
    padding: 17.7vh 2.3vw;

    @media #{$responsive-portrait} {
      padding: 13.7vh 6vw 17vh;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    color: #152CDC;
  }

  &__title {
    font-size: 10.8vw;
    line-height: 10.8vw;
    font-style: normal;
    font-weight: bold;
    text-transform: uppercase;
    display: inline-block;
    vertical-align: middle;
    word-break: break-word;

    @media #{$responsive-portrait} {
      font-size: 18.2vw;
      line-height: 16.5vw;
    }
  }

  &__rest-title {
    display: inline-block;
  }

  &__last-word {
    position: relative;
    -webkit-hyphens: auto;
    hyphens: auto;
    margin-right: .2em;
  }

  &__bean {
    display: inline-block;
    transform: translateY(10%);
    background-image: url('./icons/bean.svg');
    background-size: .55em .85em;
    width: .55em;
    height: .85em;
  }

  &__sub-title {
    margin-top: .2vw;
    display: inline-block;
    font-style: normal;
    font-weight: normal;
    font-size: 3.4vw;
    line-height: 4vw;
    text-transform: uppercase;
    word-break: break-word;

    @media #{$responsive-portrait} {
      margin-top: 4vw;
      font-size: 9vw;
      line-height: 9.8vw;
    }
  }

  &__items {
    margin-top: 4.1vw;
    margin-bottom: 10.55vw;

    @media #{$responsive-portrait} {
      margin-top: 7.7vw;
      margin-bottom: 9.8vw;
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__items {
    position: relative;
    z-index: 1;
  }

  &__item {
    margin: 8.89vw 9vw;

    @media #{$responsive-portrait} {
      margin-left: 4px;
      margin-right: 4px;
    }

    &_quote {
      margin: 13.2vw 11.73vw;

      @media #{$responsive-portrait} {
        margin: 37vw 8.5vw;
      }
    }

    &_medium-headline {
      margin: 8.3vw calc(9vw + 1px) 8.3vw 38.26vw;

      @media #{$responsive-portrait} {
        margin: 18.2vw 5px 18.2vw 16.15vw;
      }
    }

    &_video {
      margin-top: 5.27vh;
      margin-bottom: 5.27vh;

      @media #{$responsive-portrait} {
        margin-left: 4px;
        margin-right: 4px;
      }
    }

    &_images {
      margin: 12.5vh calc(9vw - 8px);

      @media #{$responsive-portrait} {
        margin: 4.74vh 0;
      }
    }
  }

  &__media-block {
    position: relative;
  }

  &__video {
    border: #80EFC4 solid 8px;
    background-color: #80EFC4;
    position: relative;

    @media #{$responsive-portrait} {
      border: #80EFC4 solid 4px;
    }

    & > div > div {
      @media #{$responsive-landscape} {
        padding: 56% 0 0 0 !important;
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #000;
    }
  }

  &__image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    border: #80EFC4 solid 4px;
  }

  &__info-block {
    display: flex;
    flex-direction: column;

    margin-right: 8.33vw;
    padding: 2vw 2.36vw 2.7vw 2.36vw;

    background-color: #80EFC4;
    color: #152CDC;

    &_body-copy:not(&_large-headline) {
      margin-right: 24.17vw;
      padding: .625vw 2.36vw .825vw 2.36vw;
    }

    &_has-media {
      margin-top: 1.56vh;
    }

    @media #{$responsive-portrait} {
      margin-right: 4.61vw;
      padding: 4.5vw 4.1vw 5.2vw 4.1vw;

      &_body-copy:not(&_large-headline) {
        padding: 2.82vw 2.82vw 3.6vw 2.82vw;
        margin-right: 4.61vw;
      }

      &_has-media {
        margin-top: 1.9vh;
      }
    }
  }

  &__medium-headline {
    background-color: #80EFC4;
    padding: 2.36vw 2.36vw 2.8vw 2.36vw;
    font-family: acumin-pro-extra-condensed, sans-serif;
    font-size: 2.92vw;
    line-height: 3.19vw;
    font-weight: 600;
    text-transform: uppercase;

    color: #152CDC;

    @media #{$responsive-portrait} {
      padding: 5.38vw 4.1vw 6.7vw 4.1vw;
      font-size: 8.2vw;
      line-height: 9.23vw;
    }
  }

  &__quote {
    position: relative;
    font-family: acumin-pro-extra-condensed, sans-serif;
    font-size: 4.44vw;
    line-height: 4.44vw;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;

    color: #152CDC;

    @media #{$responsive-portrait} {
      font-size: 8.72vw;
      line-height: 9.23vw;
    }

    &::before {
      content: '';
      position: absolute;
      background-image: url('./icons/quote.svg');
      background-size: 311.79px 296px;
      width: 311.79px;
      height: 296px;
      left: 50%;
      transform: translate(-50%, -50%);
      top: 50%;
      z-index: -1;
    }
  }

  &__under {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    &::after {
      content: '';
      position: absolute;
      background-image: var(--pattern-animation);
      background-size: 12px 12px;
      background-position: -4px -3px, 2px 3px;
      background-repeat: repeat;
      opacity: var(--opacity-animation);
      height: 100%;
      width: 100%;
      z-index: -1;
      top: var(--top-animation);
      left: var(--left-animation);

      @media #{$responsive-portrait} {
        top: var(--top-animation-mobile);
        left: var(--left-animation-mobile);
      }
    }
  }

  &__large-headline {
    background-color: #80EFC4;
    -webkit-hyphens: auto;
    hyphens: auto;
    display: inline-block;
    color: #152CDC;

    font-weight: 600;
    font-size: 3.33vw;
    line-height: 3.3vw;
    text-transform: uppercase;

    @media #{$responsive-portrait} {
      font-size: 8.2vw;
      line-height: 7.2vw;
    }
  }

  &__body-copy {
    display: inline-block;
    background-color: #80EFC4;

    color: #152CDC;

    font-family: acumin-pro-condensed, sans-serif;
    font-weight: 400;
    font-size: 1.2vw;
    line-height: 1.4vw;

    @media #{$responsive-portrait} {
      font-size: 4.1vw;
      line-height: 5vw;
    }
  }
}

.new-case-study__item_images .new-case-study__under {
  height: calc(100% - 16px);
  width: calc(100% - 16px);
  top: 8px;
  left: 8px;

  @media #{$responsive-portrait} {
    height: calc(100% - 8px);
    width: calc(100% - 8px);
    top: 4px;
    left: 4px;
  }
}

.new-case-study__item_images .new-case-study__info-block {
  margin-left: 10px;

  @media #{$responsive-portrait} {
    margin-left: 4px;
    margin-right: calc(4.61vw + 4px);
  }
}

.new-case-study__info-block_large-headline.new-case-study__info-block_body-copy .new-case-study__body-copy {
  margin-top: 1vw;

  @media #{$responsive-portrait} {
    margin-top: 2.5vw;
  }
}