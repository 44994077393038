@import "../../vars";

.disable-pointer-events {
  pointer-events: none !important;
  svg {
    // display: none;
    pointer-events: none !important;
  }
  * {
    pointer-events: none !important;
  }
}

.caveat-navigation {
  position: fixed;
  z-index: 200;
  top: 0vw;

  border: 0.5vw solid $c-blue;

  width: 100vw;
  height: 100%;
  box-sizing: border-box;
  z-index: 1000;

  pointer-events: none;
  @media #{$responsive-portrait} {
    border: 1.8vh solid $c-blue;
  }

  .caveat-navigation-logo {
    position: fixed;
    left: 2vw;
    top: 2vw;
    z-index: 999;

    .caveat-logo-svg {
      width: 6vw;
      fill: $c-blue;
      transition: all 250ms;
      &:hover {
        fill: $c-purple;
        transition: all 250ms;
      }
    }
    pointer-events: all;

    @media #{$responsive-portrait} {
      left: 3vh;
      top: 3vh;
      .caveat-logo-svg {
        width: 15vh;
        margin-left: -0.5vh;
      }
    }
  }
  .hamburger {
    position: fixed;
    right: 2.3vw;
    top: 2vw;
    z-index: 200;
  }
  .caveat-navigation-indicator {
    width: 3.5vw;

    position: fixed;
    right: 2.3vw;
    top: 2vw;
    cursor: pointer;
    fill: $c-blue;
    pointer-events: all;

    .navigation-x {
      opacity: 0;
      transition: all 50ms;
    }
    .navigation-h {
      opacity: 1;
      transition: all 50ms;
    }
    z-index: 300;
    transition: all 250ms;
    &:hover {
      fill: $c-purple;
      transition: all 250ms;
    }
    @media #{$responsive-portrait} {
      width: 5.5vh;
      right: 3vh;
      top: 3vh;
    }
  }

  .caveat-navigation-modal {
    position: fixed;
    z-index: 200;
    width: 100vw;
    height: 100vh;
    top: -100vh;
    left: 0;
    padding: 0;
    display: none;
    pointer-events: all;

    .caveat-navigation-modal-minimart {
      background-image: url('../../assets/minimart.gif');
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute;
      height: 4.8vw;
      width: 13.6vw;
      bottom: 2vw;
      right: 3vw;
      @media #{$responsive-portrait} {
        height: 15.3vw;
        width: 40.5vw;
        bottom: 15vh;
        right: 0;
        left: 0;
        margin: 0 auto;
      }
    }

    ul {
      list-style-type: none;
      position: absolute;
      padding: 0;
      margin: 0;
      width: 100vw;
      height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      @media #{$responsive-portrait} {
        align-items: flex-start;
        justify-content: flex-start;
        width: calc(100vw - 6vh);
        left: 2vh;
        top: 20vh;
      }
    }
    li {
      position: relative;
      text-align: left;
      padding: 1vw;
      @media #{$responsive-portrait} {
        padding: 1vw 3vw;
      }

      a {
        text-decoration: none;
        font-size: 6vw;
        font-family: acumin-pro-extra-condensed, sans-serif;
        text-transform: uppercase;
        font-weight: 800;
        color: $c-blue;
        &::before {
          content: "";
          // @include striped-background($c-pink, 0.1vw, 2);
          clip-path: inset(0% 0% 0% 100%);
          background-color: $c-lightGreen;
          height: 30%;
          width: 105%;
          position: absolute;
          z-index: -1;
          left: -1%;
          top: 50%;
          transform: translate(0%, -25%);
          // border:1px solid #f00;
          animation: menu-slide-out 500ms cubic-bezier(1, 0, 0, 1);
        }
  
        &:hover {
          &::before {
            clip-path: inset(0% 0% 0% 0%);
            animation: menu-slide-in 500ms cubic-bezier(1, 0, 0, 1);
          }
        }
  
        &.active {
          &::before {
            clip-path: inset(0% 0% 0% 0%);
            background-color: $c-pink;
            animation: menu-slide-in 500ms cubic-bezier(1, 0, 0, 1);
          }
        }
        @media #{$responsive-portrait} {
          font-size: 10.5vw;
          line-height: 94%;
          display: block;
          margin-bottom: 10vw;
          text-align: left;
        }
      }
    }

    

    @keyframes menu-slide-in {
      from {
        clip-path: inset(0% 100% 0% 0%);
      }
      to {
        clip-path: inset(0% 0% 0% 0%);
      }
    }

    @keyframes menu-slide-out {
      from {
        clip-path: inset(0% 0% 0% 0%);
      }
      to {
        clip-path: inset(0% 0% 0% 100%);
      }
    }
  }
}

